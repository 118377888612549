import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  id: number;

  constructor(
    private readonly popoverRef: PopoverRef,
    private auth: AuthService,
    private router: Router
  ) {
    const authData: string | null = localStorage.getItem(this.authLocalStorageToken);
    if(authData) {
      const data = JSON.parse(authData);
      this.id = data.id;
    }
   }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  logout(): void {
    this.close();
    this.auth.logout();
    this.router.navigate(['']);
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cleanup } from 'src/app/models/cleanup.model';
import { User } from 'src/app/models/customer.model';
import { Game } from 'src/app/models/game.model';
import { Litter, LitterCategory } from 'src/app/models/litter.model';
import { Location } from 'src/app/models/location.model';
import { Partner } from 'src/app/models/partner.model';
import { Team } from 'src/app/models/team.model';
import { Tip } from 'src/app/models/tip.model';

@Component({
  selector: 'vex-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {

  public fName: string;
  public fIndex: number;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    @Inject(MAT_DIALOG_DATA) public cleanup: Cleanup,
    @Inject(MAT_DIALOG_DATA) public partner: Partner,
    @Inject(MAT_DIALOG_DATA) public team: Team,
    @Inject(MAT_DIALOG_DATA) public litter: Litter,
    @Inject(MAT_DIALOG_DATA) public litterCategory: LitterCategory,
    @Inject(MAT_DIALOG_DATA) public location: Location,
    @Inject(MAT_DIALOG_DATA) public tip: Tip,
    @Inject(MAT_DIALOG_DATA) public game: Game,
    @Inject(MAT_DIALOG_DATA) public teamId: number | string
  ) {}

  onConfirm(): void {
    if(this.user) {
      this.dialogRef.close(this.user);
    }
    if(this.cleanup) {
      this.dialogRef.close(this.cleanup);
    }
    if(this.partner) {
      this.dialogRef.close(this.partner);
    }
    if(this.fName) {
      this.dialogRef.close(this.fName);
    }
    if(this.team) {
      this.dialogRef.close(this.team);
    }
    if(this.litter) {
      this.dialogRef.close(this.litter);
    }
    if(this.litterCategory) {
      this.dialogRef.close(this.litterCategory);
    }
    if(this.location) {
      this.dialogRef.close(this.location);
    }
    if(this.tip) {
      this.dialogRef.close(this.tip);
    }
    if(this.game) {
      this.dialogRef.close(this.game);
    }
    if(this.teamId) {
      this.dialogRef.close(this.teamId);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './services/auth.guard';
import { LandingComponent } from './pages/landing/landing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
    {
      path: '',
      component: LandingComponent
    },
    {
      path: 'auth',
      loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
      path: 'hints-tips/carousel',
      loadChildren: () => import('./pages/tips/tips-carousel/tips-carousel.module').then(m => m.TipsCarouselModule)
    },
    {
      path: '',
      component: CustomLayoutComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'dashboard',
          loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
        },
        {
          path: 'user',
          loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
        },
        {
          path: 'cleanup',
          loadChildren: () => import('./pages/cleanup/cleanup.module').then(m => m.CleanupModule)
        },
        {
          path: 'partner',
          loadChildren: () => import('./pages/partner/partner.module').then(m => m.PartnerModule)
        },
        {
          path: 'team',
          loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule)
        },
        {
          path: 'litter',
          loadChildren: () => import('./pages/litter/litter.module').then(m => m.LitterModule)
        },
        { 
          path: 'location-type',
          loadChildren: () => import('./pages/litter-category/litter-category.module').then(m => m.LitterCategoryModule)
        },
        {
          path: 'location',
          loadChildren: () => import('./pages/location/location.module').then(m => m.LocationModule)
        },
        {
          path: 'tips',
          loadChildren: () => import('./pages/tips/tips.module').then(m => m.TipsModule)
        },
        {
          path: 'game',
          loadChildren: () => import('./pages/game/game.module').then(m => m.GameModule)
        },
      ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
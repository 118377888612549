<div class="container-main">
    <div class="main row">
        <img src="assets/img/icons/logos/sc2-logo-landing.svg" class="big-img" alt="image-1">

        <div class="sm-img-bg one-bg" alt="button-1"></div>
        <button (click)="openLogin()" class="sm-img one" alt="button-1"><p>Login</p></button>

        <div class="sm-img-bg two-bg" alt="button-2"></div>
        <button (click)="openTips()" class="sm-img two" alt="button-2"><p>Tips</p></button>

        <div class="sm-img-bg three-bg" alt="button-3"></div>
        <button (click)="openRegistration()" class="sm-img three" alt="button-1"><p>Register</p></button>

        <div class="sm-img-bg four-bg" alt="button-4"></div>
        <button class="sm-img four" alt="button-4" (click)="openDisclaimer()"><p>Disclaimer</p></button>
    </div>
    <div class="row wrapper-logos">
        <div class="col-lg-6 col-md-6 col-sm-6 custom-column">
            <div>Designed and empowered by <br> Venice Lagoon Plastic Free</div>
            <br>
            <a href="https://www.plasticfreevenice.org/" class="link-logo" target="_blank">
                <img alt="Logo Venice Lagoon Plastic Free" class="img-logo" src="./assets/logos/logo-VLPF-3.png" style="max-height: 6.4rem !important;"/>
            </a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 custom-column column-developed">
            <div cla>Developed by <br> Infordata Sistemi</div>
            <br>
            <a href="https://infordata.it/" class="link-logo" target="_blank">
                <img alt="Logo Infordata" class="img-logo" src="./assets/logos/logo_infordata.png"/>
            </a>
        </div>
    </div>
</div>
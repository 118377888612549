export class Location {
    id: number;
    name: string;
    code: string;
    country_code: CountryCode;
    coordinate_system: string;
    beach_code: string;
    back: string;
    other_back: string;
    reference: string;
    back_development: string;
    low_width: number;
    development_description: string;
    hight_width: number;
    access: string;
    length: number;
    cleaning_seasonality: string;
    latitude: string;
    seasonality_months: string;
    longitude: string;
    cleaning_frequency: string;
}

export interface CountryCode {
    id: number;
    title: string | null;
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedDataService {
  private logoSource = new BehaviorSubject<string>('');
  currentLogo = this.logoSource.asObservable();

  private nameSource = new BehaviorSubject<string>('');
  currentName = this.nameSource.asObservable();

  private surnameSource = new BehaviorSubject<string>('');
  currentSurname = this.surnameSource.asObservable();

  changeLogo(newLogo: string) {
    this.logoSource.next(newLogo);
  }

  changeName(newName: string) {
    this.nameSource.next(newName);
  }

  changeSurname(newSurname: string) {
    this.surnameSource.next(newSurname);
  }
}
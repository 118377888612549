import { Location } from "./location.model";
import { Team } from "./team.model";

export class Game {
  id: number;
  description: string;
  address: string;
  user_id: string;
  beach_id: string;
  beach: Location;
  date: Date;
  number_of_fisherman: number;
  start_time: string;
  end_time: string;
  number_of_participants: number;
  number_of_litter_bags_collected: number;
  plastic_litter_weight: number;
  other_litter_weight: number;
  total_length_of_the_beach_cleaned: number;
  width_of_the_cleaned_beach: number;
  start_latitude: string;
  start_longitude: string;
  end_latitude: string;
  end_longitude: string;
  quantity_of_special_items_removed: number;
  disposal_location: string;
  disposal_organisation_name: string;
  disposal_datetime: Date;
  teams?: Team[];
  winning_factor: number | string;
  completed: number;
}

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { User } from 'src/app/models/customer.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  protected baseUrl = `${environment.apiUrl}`;

  constructor(
    private http: HttpClient
  ) { }

  index(params?: HttpParams): Observable<HttpResponse<User[]>> {
    return this.http.get<HttpResponse<User[]>>(`${this.baseUrl}/user`, {
      params: params,
      observe: 'response' as 'body'
    })
  }

  delete(id: number): Observable<User> {
    return this.http.delete<User>(`${this.baseUrl}/user/${id}`);
  }

  view(id: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/user/${id}`);
  }

  update(user: User): Observable<User> {
    return this.http.put<User>(`${this.baseUrl}/user/${user.id}`, user);
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + '/user', user);
  }

  deleteUserLogo(id: number) {
    return this.http.get(`${this.baseUrl}/user/delete-image?id=${id}`);
  }

  userIdOptions(): Observable<Array<{id: number, itemName: string | null}>> {
    const params = new HttpParams();
    return this.index(params.append('page', 0))
      .pipe(map((response: HttpResponse<User[]>) => {
        if (!response.body)
          return [];

        return response.body?.map((user: User) => {
          return {
            id: user.id,
            itemName: user.name + ' ' + user.surname
          }
        });
    }))
  }
}
